$colors: (
  primary: #ff0000,
  secondary: #424141,
  background: #fafaf9,
  //text
    text: #2f2f2f,
  text_light: #636284,
  highlight: #f0dfdb,
  muted: #cac8c8,
  //input
    input_bg: #e6e6e6,
  //basic colors
    success: #46e88b,
  danger: #e84646,
) !default;

//font-family
$font-primary: "Poppins", sans-serif !default;

//transitions
$text-transition-3: all 0.2s ease;

.slick-prev:before,
.slick-next:before {
  color: #fff !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white !important;
}
