.lower-footer {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.footer-light {
  background-color: #fff !important;
  color: #000 !important;
}

.footer-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;

  @include respond-to("sm") {
    position: relative;
  }
}
