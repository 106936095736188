$breakpoints: (
  "xs": 576px,
  "sm": 767px,
  "md": 992px,
  "lg": 1200px,
  "xl": 1600px,
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
