//main---------------------------------
html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
}

//text----------------------------------

a {
  color: color(secondary) !important;
  text-decoration: none;
  transition: $text-transition-3;

  &:hover {
    color: color(primary) !important;
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer !important;
  user-select: none !important;
}

.hover-text {
  color: color(secondary);
  text-decoration: none;
  transition: $text-transition-3;

  &:hover {
    color: color(primary);
    text-decoration: none;
  }
}

//shadows--------------------------------
.shadow-normal {
  -ms-box-shadow: rgba(0, 0, 0, 0.12) 0 10px 60px;
  -o-box-shadow: rgba(0, 0, 0, 0.12) 0 10px 60px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.12);
}

.primary-button {
  color: #fff !important;
  background-color: color(primary);
  transition: all 0.3s ease;
  border-radius: 5px !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: lighten($color: color(primary), $amount: 10);
  }
}
