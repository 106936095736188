.question-answers-view {
  background: #fff;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @extend .shadow-normal;

  @include respond-to("sm") {
    height: 100vh;
  }
}

.question-list-view {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  border: 1px solid rgba($color: #000000, $alpha: 0.05);
}

/* width */
#style-1::-webkit-scrollbar {
  width: 3px;
}

/* Track */
#style-1::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#style-1::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
#style-1::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.overflow-view {
  position: absolute;
  width: 100%;
}

.question-main {
  user-select: none;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
}

.question-wrapper {
  width: 100%;
  position: relative;
}
.question-item {
  .question-profile {
    margin-right: 10px;
    .profile-wrapper {
      position: relative;
      width: 50px;
      height: 50px;

      img {
        border-radius: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

.question-profile-content {
  p {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: color(text);
  }
}

.question-time {
  color: color(muted);
  font-size: 11px;
  margin-left: 5px;
}

.question-value {
  font-size: 14px;
  width: 100%;
  word-wrap: break-word;
}

.like-button {
  position: absolute;
  right: 0;
}

.add-question {
  height: 20%;
}

//answer------------------------------

.answered-label {
  font-size: 12px;
  margin: 0px 10px 0px 10px;
  padding: 2px 5px 2px 5px;
  color: color(success);
  border-radius: 5px;
  border: 1px solid color(success);
}

.answers-view {
  border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
}

.answer-item {
  .answer-profile {
    margin-right: 10px;
    .profile-wrapper {
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      // background: rgba($color: #000000, $alpha: 0.5);

      img {
        border-radius: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
