.theme-navbar {
  @extend .shadow-normal;
}

.theme-navbar-dropdown {
  @extend .shadow-normal;
  border: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: color(primary) !important;
}

.fixed-top-navbar {
  position: fixed;
  top: 0;
  left: 0;

  @include respond-to("sm") {
    position: relative;
  }
}
