/*//////////////////////////////////////////////////////////////////
[ login ]*/

.label-input {
  color: color(text_light);
}

.logo-wrapper {
  display: flex;
  width: 100%;
  padding: 30px;
  // background-color: red;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 200px;
}

.limiter {
  width: 100%;
  margin: 0 auto;
  user-select: none;
  overflow: hidden;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  //   background: url("../../assets/svg/login_background.svg");
  background-position: bottom right;
  background-size: 600px;
  background-repeat: no-repeat;

  @include respond-to("md") {
    background: #fff;
  }
}

.wrap-login100 {
  width: 35%;
  padding: 50px;

  background: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;

  @include respond-to("md") {
    width: 50%;
  }

  @include respond-to("sm") {
    padding: 20px;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

/*==================================================================
  [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  // font-family: $primary_font;
  font-weight: bold;
  font-size: 40px;
  color: color(primary);
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  width: 100%;
  display: block;
}

/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: color(input_bg);
  border: 1px solid transparent;
  border-radius: 3px;
}

/*---------------------------------------------*/
.input100 {
  outline: none;
  border: none;
  color: color(text);
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}
/*------------------------------------------------------------------
  [ Focus Input ]*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid color(primary);
  border-radius: 3px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*==================================================================
  [ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  // font-family: $primary_font;
  font-size: 16px;
  color: color(text_light);
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: #fff;
  border: 2px solid color(primary);
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: color(primary);
}

/*------------------------------------------------------------------
  [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.login100-form-btn {
  // font-family: $primary_font;
  font-size: 20px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 62px;
  background-color: color(primary);
  border-radius: 3px;
  border-color: transparent;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  outline: none !important;

  &:active {
    background-color: color(primary) !important;
    border-color: transparent !important;
    outline: none !important;
  }
  &:focus {
    background-color: color(primary) !important;
    border-color: transparent !important;
    box-shadow: none;
    outline: none !important;
  }
}

.login100-form-btn:hover {
  background-color: color(primary) !important;
  border-color: transparent !important;
  box-shadow: none;
  outline: none !important;
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 3px;
  padding: 4px 25px 5px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  // font-family: $primary_font;
  color: #c80000;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.txt1 {
  font-size: 16px;
  color: color(primary);
  line-height: 1.4;
}

.error-messasge {
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  margin-left: 3px;
  color: color(danger);
}

.cancel-otp {
  background: #eee;
  display: block;
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
/*//////////////////////////////////////////////////////////////////
  [ Responsive ]*/

.profile-photo-wrapper {
  display: flex;
  width: 100%;
  padding: 10px 30px 30px 30px;
  // background-color: red;
  align-items: center;
  justify-content: center;
}
.profile-photo {
  width: 150px;
}
.wrap-profile100 {
  width: 40%;
  padding: 30px 50px;

  background: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;

  @include respond-to("md") {
    width: 50%;
  }

  @include respond-to("sm") {
    padding: 20px;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.ReactPasswordStrength.strength-input {
  border: 0px solid transparent;
  padding: 0;

  .ReactPasswordStrength-input {
    width: 100%;
    padding: 14px 0 12px 38px !important;
  }
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
  width: 100% !important;
  top: 0 !important;
}
.ReactPasswordStrength-strength-desc {
  top: 10px !important;
  right: 20px !important;
}

.modal-dialog {
  .modal-content {
    border: 0px solid transparent !important;
  }
}

.error-message-modal {
  color: color(danger);
}

.error-close-button {
  background-color: color(danger);
  border-color: color(danger);
  margin-left: 20px !important;

  &:hover {
    background-color: color(danger);
    border-color: color(danger);
  }
}
